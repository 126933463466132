class registerModalComponent extends Component {

    static name() {
        return "registerModalComponent";
    }

    static componentName() {
        return "registerModalComponent";
    }

    data() {
        return {
            personName: this.$store.getters.getSession,
            personLastName:'',
            personEmail:'',
            personPhone:'',
            customerTaxNr:'',
            readOnly:false,
            inSystem:false,
            WebUser: '',
            WebPassword: ''
        };
    }

    created() {
        return function () {
            this.FavouriteName=null;
        };
    }

    getWatch() {
        return {
            'inSystem':function () {
                console.log('Change ',this.inSystem);
            }
        };
    }

    getMethods() {
        return {
            registerNewUser:this.registerNewUser,
            cuitValidator:this.cuitValidator
        };
    }

    registerNewUser(){
        let has_error = false;
        if (!this.personName) {
            has_error = true;
        }
        if (!this.personLastName) {
            has_error = true;
        }
        if (!this.personEmail) {
            has_error = true;
        }
        if (!this.personPhone) {
            has_error = true;
        }
        if (!this.WebUser) {
            has_error = true;
            console.log('Errorr webUser')
        }
        if (!this.WebPassword) {
            has_error = true;
            console.log('Errorr webPassword')
        }
        if(!has_error) {
            $.post('/ecommerce/api/newPerson', this.$data)
                .done(function (response) {
                    if(response.success==true) {
                        $('#registerModal').modal('open');
                    }
                })
                .fail(function (error) {
                    console.log('Error response in Register New User', error);
                });
        }
        else{
            console.log('Errorr')
            Materialize.toast('Error in forms');
        }
    }

    cuitValidator(){
        console.log('CUIT',this.customerTaxNr);
    }

    getTemplate() {
        return `<div id="registerModal" class="modal cart-modal">
              <div class="modal-content">
                <h5 class="center-align">Welcome</h5>
                <div class="col s12">
                   <div class="row">
                        <div class="row no-margin">
                            <div class="col s6 input-field">
                              <i class="fa fa-user prefix" aria-hidden="true"></i>
                              <input v-model="personName" type="text" class="validate">
                              <label for="username">First Name</label>
                            </div>
                            <div class="col s6 input-field">
                              <i class="fa fa-user prefix" aria-hidden="true"></i>
                              <input v-model="personLastName" type="text" class="validate">
                              <label for="username">Last Name</label>
                            </div>
                        </div>
                        <div class="row no-margin">
                              <div class="col s6 input-field">
                                  <i class="fa fa-envelope prefix" aria-hidden="true"></i>
                                  <input v-model="personEmail" type="email" class="validate">
                                  <label for="username" data-error="Invalid Email">Email</label>
                              </div>
                              <div class="col s6 input-field">
                                  <i class="fa fa-phone prefix" aria-hidden="true"></i>
                                  <input v-model="personPhone" type="text" class="validate">
                                  <label for="username">Contact Phone</label>
                              </div>
                        </div>
                        <div class="row no-margin">
                            <div class="input-field col s6">
                                <i class="fa fa-user prefix" aria-hidden="true"></i>
                                <input class="validate" pattern="[0-9]{2}" v-model="customerTaxNr" type="number" min=010000000000 max=999999999999 @mouseleave="cuitValidator">
                                <label for="customerTaxNr" data-error="Wrong CUIT/CUIL">CUIT/CUIL</label>
                            </div>
                            <div class="switch">
                              <label>
                                  Already in System
                                  <input v-model="inSystem" id="inSystem" type="checkbox">
                                  <span class="lever"></span>
                                  New Client
                              </label>
                            </div>
                        </div>
                        <div class="row no-margin">
                            <div class="input-field col s6">
                                <i class="fa fa-user prefix" aria-hidden="true"></i>
                                <input class="validate" v-model="WebUser" type="text">
                                <label for="username" data-error="User already exits">User</label>
                            </div>
                            <div class="input-field col s6">
                                <i class="fa fa-lock prefix" aria-hidden="true"></i>
                                <input v-model="WebPassword" class="validate" type="password" required>
                                <label for="password" data-error="Password can't be empty">Password</label>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button  class="btn waves-effect waves-light" @click.prevent="registerNewUser">Register</button>
                <button  class="btn modal-action modal-close  waves-effect waves-light" >Close</button>
              </div>
            </div>`;
    }
}

registerModalComponent.registerComponent();

/*

Vue.component('registerComponent',{
    methods:{
        registerNewUser:function(){
            let has_error = false;
            if (!this.personName) {
                has_error = true;
            }
            if (!this.personLastName) {
                has_error = true;
            }
            if (!this.personEmail) {
                has_error = true;
            }
            if (!this.personPhone) {
                has_error = true;
            }
            if (!this.WebUser) {
                has_error = true;
                console.log('Errorr webUser')
            }
            if (!this.WebPassword) {
                has_error = true;
                console.log('Errorr webPassword')
            }
            if(!has_error) {
                $.post('/ecommerce/api/newPerson', this.$data)
                    .done(function (response) {
                        if(response.success==true) {
                            $('#registerModal').modal('open');
                        }
                    })
                    .fail(function (error) {
                        console.log('Error response in Register New User', error);
                    });
            }
            else{
                console.log('Errorr')
                Materialize.toast('Error in forms');
            }
        },
        cuitValidator:function(){
            console.log('CUIT',this.customerTaxNr);
        }
    },
    data:function() {
        return {
            personName: this.$store.getters.getSession,
            personLastName:'',
            personEmail:'',
            personPhone:'',
            customerTaxNr:'',
            readOnly:false,
            inSystem:false,
            WebUser: '',
            WebPassword: ''
        }
    },
    watch:{
        'inSystem':function () {
            console.log('Change ',this.inSystem);
        }
    },
    template:`
            <div id="registerModal" class="modal cart-modal">
              <div class="modal-content">
                <h5 class="center-align">Welcome</h5>
                <div class="col s12">
                   <div class="row">
                        <div class="row no-margin">
                            <div class="col s6 input-field">
                              <i class="fa fa-user prefix" aria-hidden="true"></i>
                              <input v-model="personName" type="text" class="validate">
                              <label for="username">First Name</label>
                            </div>
                            <div class="col s6 input-field">
                              <i class="fa fa-user prefix" aria-hidden="true"></i>
                              <input v-model="personLastName" type="text" class="validate">
                              <label for="username">Last Name</label>
                            </div>
                        </div>
                        <div class="row no-margin">
                              <div class="col s6 input-field">
                                  <i class="fa fa-envelope prefix" aria-hidden="true"></i>
                                  <input v-model="personEmail" type="email" class="validate">
                                  <label for="username" data-error="Invalid Email">Email</label>
                              </div>
                              <div class="col s6 input-field">
                                  <i class="fa fa-phone prefix" aria-hidden="true"></i>
                                  <input v-model="personPhone" type="text" class="validate">
                                  <label for="username">Contact Phone</label>
                              </div>
                        </div>
                        <div class="row no-margin">
                            <div class="input-field col s6">
                                <i class="fa fa-user prefix" aria-hidden="true"></i>
                                <input class="validate" pattern="[0-9]{2}" v-model="customerTaxNr" type="number" min=010000000000 max=999999999999 @mouseleave="cuitValidator">
                                <label for="customerTaxNr" data-error="Wrong CUIT/CUIL">CUIT/CUIL</label>
                            </div>
                            <div class="switch">
                              <label>
                                  Already in System
                                  <input v-model="inSystem" id="inSystem" type="checkbox">
                                  <span class="lever"></span>
                                  New Client
                              </label>
                            </div>
                        </div>
                        <div class="row no-margin">
                            <div class="input-field col s6">
                                <i class="fa fa-user prefix" aria-hidden="true"></i>
                                <input class="validate" v-model="WebUser" type="text">
                                <label for="username" data-error="User already exits">User</label>
                            </div>
                            <div class="input-field col s6">
                                <i class="fa fa-lock prefix" aria-hidden="true"></i>
                                <input v-model="WebPassword" class="validate" type="password" required>
                                <label for="password" data-error="Password can't be empty">Password</label>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button  class="btn waves-effect waves-light" @click.prevent="registerNewUser">Register</button>
                <button  class="btn modal-action modal-close  waves-effect waves-light" >Close</button>
              </div>
            </div>`
});*/
